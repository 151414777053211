import HeaderContainer_AfterScrollComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/AfterScroll/AfterScroll.skin';


const HeaderContainer_AfterScroll = {
  component: HeaderContainer_AfterScrollComponent
};


export const components = {
  ['HeaderContainer_AfterScroll']: HeaderContainer_AfterScroll
};


// temporary export
export const version = "1.0.0"
